/* Grocery Drop Down */
.store-drop-down-workspace {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.store-drop-down-input {
  flex: 1 1 80px;
  display: flex;
}

.store-drop-down-input > input {
  text-transform: capitalize;
}

.store-drop-down-overlay  {
  position: absolute;
  max-width: 250px;
  box-sizing: border-box;
  padding: 0px 5px;
  border: 1px solid var(--theme-background-color);

  z-index: 10;
  cursor: pointer;
  text-transform: capitalize;
}

.store-drop-down-overlay > div {
  padding: 5px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Grocery List */
.grocery-list-root > .grocery-list-store .input-box,
.grocery-list-root > .grocery-item-add-root .input-box,
.grocery-list-root > .grocery-list-items .grocery-item-input > .input-box,
.grocery-list-collected-items > .grocery-list-collected-root {
  padding: 0px 0px 0px 5px;
  box-sizing: border-box;
}

/* Grocery Store */
.grocery-list-store {
  height: var(--format-line-height);
}

/* Grocery Add Item */
.grocery-item-add-root {
  display: flex;
  flex-direction: row;
  height: var(--format-heading-small-height);
}

/* Grocery Item List */
.grocery-item-root {
  height: var(--format-line-height);
  display: flex;
  flex-direction: row;
}

.grocery-list-items > .grocery-item-root:nth-child(odd),
.grocery-list-collected-items > .grocery-list-collected-root:nth-child(odd) {
  background-color: var(--theme-focus-color);
}

.grocery-list-items > .grocery-item-root:nth-child(even),
.grocery-list-collected-items > .grocery-list-collected-root:nth-child(even) {
  background-color: var(--theme-surface-color);
}

.grocery-item-input {
  flex: 1 1 100px;
  text-transform: capitalize;
}

.grocery-item-store {
  flex: 1 0 100px;
  max-width: 250px;
}

.grocery-item-collect {
  width: var(--format-line-height);
}

.grocery-list-items-empty {
  width: 100%;
  height: var(--format-line-height);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grocery-item-delete-indicator {
  position: absolute;
  height: var(--format-line-height);
  background-color: var(--theme-alert-color);
}

.grocery-item-delete-indicator > img {
  position: absolute;
  right: 0;
  height: var(--format-line-height);
}

.grocery-item-collect-indicator {
  background-color: green;
}

/* Collected Grocery Item Divider */
.grocery-list-collected-divider {
  height: var(--format-line-height);
  display: flex;
  flex-direction: row;
}

/* Collected Grocery Items */
.grocery-list-collected-space {
  flex-grow: 1;
  display: flex;
  padding-left: 5px;
  align-items: center;
}

.grocery-list-collected-clear-all {
  height: var(--format-line-height);
  width: var(--format-line-height);
}

.grocery-list-collected-root {
  height: 25px;
  display: flex;
  flex-direction: row;
}

.grocery-collected-item {
  flex-grow: 2;
}

.grocery-collected-store {
  flex-grow: 1;
  max-width: 100px;
}

.grocery-list-collected-empty {
  padding: 5px 10px;
  text-align: center;
}