.widget-slide-indicator {
  position: absolute;
}

.widget-slide-indicator > img {
  position: absolute;
}

.widget-interget-input-root {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.widget-interget-input-value {
  width: 35px;
  text-align: center;
}