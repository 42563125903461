.input-box, .input-number-box, .input-password-box {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-color: inherit;
}

.input-number-box {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.input-text-button {
  padding: 10px 15px;
  border: 1px solid var(--theme-background-color);
  border-radius: 5px;
  font: inherit;
}

.input-single-checkbox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-disabled {
  pointer-events: none;
}