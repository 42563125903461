body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, input, textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.app-root {
  height: 100vh;
}

/* Home */
.home-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-greeting {
  width: 100%;
  padding: 5px 0px;

  text-align: center;
}

.home-login-link {
  padding: 10px;
  margin: 15px;

  border-radius: 5px;
}

/* Workspace */
.workspace-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.workspace-menu-bar {
  position: relative;
  width: 100%;
  display: flex;

  padding: 5px 10px;
  box-sizing: border-box;
  flex-direction: row;
}

.workspace-menu-bar-show-btn {
  width: var(--format-heading-small-height);
  height: var(--format-heading-small-height);
}

.workspace-menu-bar-context-sensitive {
  flex-grow: 1;
}

.workspace-menu-bar-overlay {
  position: absolute;
  top: var(--format-heading-small-height);
  left: 0px;
  width: 100%;

  padding: 5px 10px;
  box-sizing: border-box;
  justify-content: space-between;
}

.workspace-main {
  flex-grow: 1;
  overflow-y: scroll;
}