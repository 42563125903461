.modal-panel-root {
  position: fixed;
  left: 0; top: 0; right: 0; bottom: 0;
  background-color: rgba(169, 169, 169, 0.95);
}

.modal-panel-boundary {
  position: absolute;
  left: 0; top: 0; right: 0; bottom: 0;
}

.modal-panel-workspace {
  width: 100%;
}

.modal-panel-close {
  display: flex;
  height: var(--format-line-height);
}

.modal-panel-close-btn {
  width: var(--format-line-height);
}

.modal-panel-content {
  width: 100%;
  padding: 5px 0px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.modal-panel-content > *{
  height: max-content;
}