/* Inventory Item */
.inventory-item-root {
  display: flex;
  flex-direction: row;
  padding: 5px 5px;
}

/* Inventory Add Item */
.inventory-list-add-item {
  padding: 0px 0px 0px 5px;
  height: var(--format-heading-small-height);
}

.inventory-list-add-item-input {
  height: 100%;
}

/* Inventory item list */
.inventory-item-root {
  height: var(--format-line-height);
}

.inventory-list-items>.inventory-item-root:nth-child(even) {
  background-color: var(--theme-surface-color);
}

.inventory-item-input {
  flex-grow: 1;
}

.inventory-item-show-more {
  height: var(--format-line-height);
  width: var(--format-line-height);
}

.inventory-list-empty {
  width: 100%;
  padding: 5px 0px;
  text-align: center;
}

/* Inventory Modal */
.inventory-modal-root {
  display: flex;
  flex-direction: column;
}

.inventory-modal-root > *{
  height: var(--format-line-height);
}

.inventory-modal-item {
  padding-left: 5px;
  opacity: 1;
}

.inventory-modal-item > input {
  font-size: var(--format-font-size-medium);
}

.inventory-modal-actions {
  display: flex;
  justify-content: space-evenly;
}

.inventory-modal-delete-btn {
  width: var(--format-line-height);
}