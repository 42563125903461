/* Recipe Index */
.recipe-index-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.recipe-list-sub-menu {
  display: flex;
  flex-direction: row;
  height: var(--format-heading-small-height);
}

.recipe-list-sub-menu-icon {
  height: var(--format-heading-small-height);
  width: var(--format-heading-small-height);
  padding: 5px 5px;
  box-sizing: border-box;
}

.recipe-list-recipes > .recipe-list-element-root:nth-child(even)>*>*,
.recipe-search-list-recipes > .recipe-search-list-element-root:nth-child(even)>*>*,
.recipe-trash-list-recipes > .recipe-trash-list-element-root:nth-child(even)>*>*{
  background-color: var(--theme-surface-color);
}

.recipe-list-element-root, .recipe-list-element-root>a,
.recipe-search-list-element-root, .recipe-search-list-element-root>a,
.recipe-trash-list-element-root, .recipe-trash-list-element-root>a {
  display: contents;
}

.recipe-list-element-root>a, .recipe-search-list-element-root>a, .recipe-trash-list-element-root>a {
  color: unset;
  text-decoration: none;
}

.recipe-list-element-name,
.recipe-search-list-element-name,
.recipe-trash-list-element-name {
  height: var(--format-line-height);

  display: flex;
  align-items: center;
  padding-left: 5px;
  box-sizing: border-box;

  overflow: hidden;
}

.recipe-list-element-name>span,
.recipe-search-list-element-name>span,
.recipe-trash-list-element-name>span{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Recipe List */
.recipe-list-root {
  height: 100%;
}

.recipe-list-recipes, .recipe-trash-list-recipes {
  display: grid;
  grid-template-columns: 1fr var(--format-line-height);
  overflow-y: scroll;
}

.recipe-list-element-trash, .recipe-list-element-untrash {
  display: flex;
  align-items: center;
  margin-right: 5px;
  height: var(--format-line-height);
  width: var(--format-line-height);
}

.recipe-list-empty {
  width: 100%;
  text-align: center;
  padding: 5px 0px;
}
